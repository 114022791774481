import React, { useEffect, useState } from "react";
import "./live-length.css";
import "../live-drop-item/live-drop-item.css";

import { ReactComponent as MenuIcon } from "../../assets/icons/live-menu.svg";
import { ReactComponent as LiveBestIcon } from "../../assets/icons/live-bests.svg";
import { ReactComponent as BestDropIcon } from "../../assets/icons/best-drop.svg";
import userImg from "../../assets/images/live-profile-photo.png";

import LiveDropItem from "../live-drop-item/live-drop-item";
import { NavLink } from "react-router-dom";
import useWebSocket from 'react-use-websocket';

function LiveLength() {
  const [items, setItems] = useState([]);
  const { sendMessage, lastMessage } = useWebSocket('wss://server.primoloot.com/ws/live');

  useEffect(() => {
    sendMessage(JSON.stringify({ action: 'get_luxury_item' }));

    const itemsInterval = setInterval(() => {
      sendMessage(JSON.stringify({ action: 'get_luxury_item' }));
    }, 15000);

    return () => {
      clearInterval(itemsInterval);
    };
  }, []);

  useEffect(() => {
    if (lastMessage) {
      const data = JSON.parse(lastMessage.data)
      setItems(data);
    }
  }, [lastMessage]);

  return (
    <div className="live_length">
      <div className="live_length_wrapper">
        <div className="left_live_buttons">
          <div className="left_live_button live_menu">
            <MenuIcon />
          </div>
          <div className="left_live_button live_best">
            <LiveBestIcon />
          </div>
        </div>
        <div className="live_length_items">
          <div className="best_drop">
            <div className="best_drop_top_line"></div>
            <div className="best_drop_content">
              <div>
                <p>Шикарный дроп!</p>
                <p>
                  {items.name} <br /> <span>кейс</span>
                </p>
              </div>
              <img src={`${items?.image}`} alt="" className="get_luxury_item_image" />
            </div>

            <NavLink to={`/user/${items?.user?.id}`}>
              <div className="live_drop_item_user">
                <div className="live_drop_icon">
                  <img src={`${items.user?.image}`} alt='' className="get_items_user_image" />
                </div>
                <div className="live_drop_item_descr">
                  <p>{items.user?.firstName && items.user?.lastName ? `${items.user.firstName} ${items.user.lastName}` : `${items.user?.username}`}</p>
                </div>
              </div>
            </NavLink>
          </div>
          <LiveDropItem />
        </div>
      </div>
    </div>
  );
}

export default LiveLength;
