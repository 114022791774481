import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { mainApi } from "../../../../components/utils/main-api";
import { formatNumber } from "../../../../helpers/price";
import { snackbarAction, snackbarClearAction } from "../../../../redux/snackbar-reducer";
import Pagination from "../../../../components/pagionation/pagination";
import PaginationLimit from "../../../../components/PaginationLimit/paginationLimit";
import RefOutputItem from "../../../../components/ref-output-item/ref-output-item";

import { ReactComponent as EditIcon } from "../../../../assets/icons/edit-icon.svg";
import { ReactComponent as CopyIcon } from "../../../../assets/icons/copy-icon.svg";
import { ReactComponent as Icon1 } from "../../../../assets/icons/ref-icons/icon-1.svg";
import { ReactComponent as Icon2 } from "../../../../assets/icons/ref-icons/icon-2.svg";
import { ReactComponent as Icon3 } from "../../../../assets/icons/ref-icons/icon-3.svg";
import { ReactComponent as Icon4 } from "../../../../assets/icons/ref-icons/icon-4.svg";
import "./affiliate-program.css";
import { modalWithdrawAction } from "../../../../redux/modal-withdraw-reducer";

const urlRef = `${'https://server.primoloot.com'}/ref/`;

function AffiliateProgram() {
  const dispatch = useDispatch();

  const [ref, setRef] = useState();
  const [editRefLink, setEditRefLink] = useState(false);
  const [valueRefLink, setValueRefLink] = useState();

  const [items, setItems] = useState([]);
  const [dataLength, setDataLength] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState({ value: '10', label: '10' });

  const snackbarActions = (snackText, error) => {
    dispatch(snackbarAction({snackText, error}))
    setTimeout(() => {
      dispatch(snackbarClearAction())
    }, 2000);
  };

  const modalWithdrawActions = () => {
    dispatch(modalWithdrawAction())
  };

  const getRefOutput = (limit, offset) => {
    mainApi
      .refOutput(limit, offset)
      .then((data) => {
        setItems(data.results);
        setDataLength(data.count);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };
  
  const getUserRef = () => {
    mainApi
      .UserRefAction()
      .then((data) => {
        setRef(data);
        setValueRefLink(data.ref_link)
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  const onKeyDownRefLink = () => {
    mainApi
      .userRefPOSTAction({
        "code_data": valueRefLink.replace(urlRef, "")
      })
      .then((data) => {
        snackbarActions('Реферальная ссылка изменена');
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  const copyURL = () => {
    const input = document.createElement('input');
    input.value = valueRefLink;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    snackbarActions('URL скопирован в буфер обмена');
  }

  useEffect(() => {
    getRefOutput(limit.value, currentPage * limit.value);
  }, [currentPage, limit]);

  useEffect(() => {
    getUserRef();
  }, []);

  return (
    <div className="profile_content_wrapper referal_content">
      <div className="ref_block_title">
        <h3>Реферальная система</h3>
      </div>
      <div className="ref_link_block">
        <div className="ref_link">
          <input
            type="text"
            value={valueRefLink || ''}
            placeholder="-"
            className="ref_link_input"
            readOnly={!editRefLink}
            onChange={(e) => {
              if (e.target.value !== `${'https://server.primoloot.com'}/ref`) {
                setValueRefLink(e.target.value)
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setEditRefLink(false)
                onKeyDownRefLink();
              }
            }}
          />
        </div>
        <div className="user_id_actions ref_actions">
          <div className="grey_icon" onClick={copyURL}>
            <CopyIcon />
          </div>
          <div className="grey_icon" onClick={() => setEditRefLink(true)}>
            <EditIcon />
          </div>
        </div>
      </div>
      <div className="ref_description_cards">
        <div className="ref_card">
          <Icon1 />
          <div>
            <p>{formatNumber(ref?.invited)}</p>
            <span>Приглашено</span>
          </div>
        </div>
        <div className="ref_card">
          <Icon2 />
          <div>
            <p>{ref?.partner_percent}%</p>
            <span>Процент</span>
          </div>
        </div>
        <div className="ref_card">
          <Icon3 />
          <div>
            <p>{formatNumber(ref?.available_withdrawal)}₽</p>
            <span>Доступно к вывода</span>
          </div>
        </div>
        <div className="ref_card ref_get_cash orange_btn" onClick={() => modalWithdrawActions()}>
          <Icon4 />
          <p>ВЫВЕСТИ</p>
        </div>
      </div>
      <div className="ref_descriptions">
        <p>
          Для приглашения отправьте вашу реферальную ссылку тому, кто ни разу не
          регистрировался на сайте (иначе не сработает)
        </p>
        <p>
          Получать примогемы, крутить крутки бесплатно реально? Партнерская
          программа говорит да! Вам будет начисляться 5% на баланс от каждого
          пополнения приглашенного вами пользователя. Сумма пополнения при этом
          для него не увеличивается, а с промокодом он получает еще больше.
          Таким образом вы будете копить баланс.
        </p>
      </div>
      <div className="ref_output_wrapper">
        {items && items.map((items) => (
          <RefOutputItem items={items} key={items.ref_output_id}/>
        ))}
        {dataLength > 10 && (
          <div className="">
            <div className="cases_paginations">
              <Pagination
                pageCount={Math.ceil(dataLength / limit.value)}
                onPageChange={setCurrentPage}
                currentPage={currentPage}
              />
            </div>
            <div className="">
              <PaginationLimit limit={limit} setLimit={setLimit} onPageChange={setCurrentPage} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AffiliateProgram;
