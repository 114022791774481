const SHOWMODAL = "SHOWMODAL";
const CLEARMODAl = "CLEARMODA";

const initialState = {
  show: false,
};

const modalWithdrawReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOWMODAL:
      return {
        ...state,
        show: true
      };
    case CLEARMODAl:
      return {
        ...state,
        show: false
      };
    default:
      return state;
  }
};

export const modalWithdrawAction = (payload) => {
  return {
    type: SHOWMODAL
  };
};

export const modalWithdrawClearAction = () => {
  return {
    type: CLEARMODAl
  };
};

export default modalWithdrawReducer;
