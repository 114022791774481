import React, { useEffect, useState } from 'react';

import './arrow-upgrade.css'; 
import { ReactComponent as Arrow } from "../../assets/icons/arrow-upgrade.svg";

const ArrowUpgrade = ({ minStopAngle, maxStopAngle }) => {
  const [rotation, setRotation] = useState(0);

  const spinertia = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  useEffect(() => {
    setRotation(spinertia(minStopAngle, maxStopAngle) + 720);
    
    return () => {
      setRotation(0);
    }
  }, [])

  return (
    <div className="deal-wheel is-spinning">
      <div className="spinner" style={{ '--rotate': rotation }}>
        <Arrow />
      </div>
    </div>
  );
};
export default ArrowUpgrade;
