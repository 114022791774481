import React from "react";
import "./snackbar.css";

function Snacbar({ text, snackbar }) {
  return (
    <>
      <div className={snackbar.error ? "snackbar_wrapper snackbar_wrapper_error" : "snackbar_wrapper snackbar_wrapper_success"}>
        <p>{text}</p>
        <div className="snackbar_error">
          {snackbar && snackbar.error && Object.keys(snackbar.error).map((e,i) => {
            return <p key={i}>{snackbar.error[e]}</p>
          })}
        </div>
      </div>
    </>
  );
}

export default Snacbar;