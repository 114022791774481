import React, { useState } from "react";
import { useEffect } from "react";

import { mainApi } from "../utils/main-api";
import CasesCategory from "../cases-category/cases-category";

import "./cases-characters.css";

function CasesCharacters() {
  const [casesCategory, setCasesCategory] = useState([]);

  useEffect(() => {
    mainApi
      .getCasesCategory()
      .then((data) => {
        setCasesCategory(data.results);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  }, []);

  return (
    <div className="cases_block cases_characters">
      <div className="cases_block_characters">
        {casesCategory && casesCategory.map((category) => (
          <CasesCategory key={category.category_id} name={category.name} category_id={category.category_id} />
        ))}
      </div>
    </div>
  );
}

export default CasesCharacters;
