import React, { useState } from "react";
import Select from "react-select";

import "./paginationLimit.css";

const option = [{
    value: "10",
    label: "10",
  },
  {
    value: "30",
    label: "30",
  },
  {
    value: "50",
    label: "50",
  },
  {
    value: "100",
    label: "100",
  },
  {
    value: "500",
    label: "500",
  },
  {
    value: "1000",
    label: "1000",
  }
]

function PaginationLimit({ limit, setLimit, onPageChange }) {
  const [open, setOpen] = useState(false);

  const selectStyles = (open) => ({
    singleValue: (provided) => ({
      ...provided,
      color: 'rgba(255, 255, 255, 0.6)',
      background: '#353F71',
    }),
    menu: (provided) => ({
      ...provided,
      height: open ? "230px" : "0px",
      overflow: "hidden",
      opacity: open ? 1 : 0,
      transition: "all 0.3s ease-in-out",
      visibility: open ? "visible" : "hidden",
      background: '#353F71',
      color: 'rgba(255, 255, 255, 0.6)',
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: '#353F71',
      color: 'rgba(255, 255, 255, 0.6)',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? '#191d3e'
        : isFocused
        ? 'rgb(123 128 152)'
        : undefined,
        color: 'rgba(255, 255, 255, 0.6)',
        cursor: 'pointer',
      };
    },
  });

  const handlePageClick = (event) => {
    onPageChange(0);
    setLimit(event);
  };

  return (
    <div
      onClick={() => setOpen(!open)}
      className="conditions_select"
    >
      <Select
        options={option}
        className="conditions_select_box select_pagination"
        onChange={handlePageClick}
        onBlur={() => setOpen(false)}
        value={limit}
        menuIsOpen
        styles={selectStyles(open)}
        menuPlacement='top'
      />
    </div>
  );
}

export default PaginationLimit;
