import React, { useEffect, useState } from "react";
import card1 from "../../assets/images/topup/card-1.png";
import card10 from "../../assets/images/topup/card-10.png";
import card11 from "../../assets/images/topup/card-11.png";
import card12 from "../../assets/images/topup/card-12.png";
import card13 from "../../assets/images/topup/card-13.png";
import card14 from "../../assets/images/topup/card-14.png";
import card15 from "../../assets/images/topup/card-15.png";
import card2 from "../../assets/images/topup/card-2.png";
import card3 from "../../assets/images/topup/card-3.png";
import card4 from "../../assets/images/topup/card-4.png";
import card5 from "../../assets/images/topup/card-5.png";
import card6 from "../../assets/images/topup/card-6.png";
import card7 from "../../assets/images/topup/card-7.png";
import card8 from "../../assets/images/topup/card-8.png";
import card9 from "../../assets/images/topup/card-9.png";
import card16 from "../../assets/images/topup/card-16.png";
import card17 from "../../assets/images/topup/card-17.png";
import card18 from "../../assets/images/topup/card-18.png";
import card19 from "../../assets/images/topup/card-19.png";
import card20 from "../../assets/images/topup/card-20.png";
import card21 from "../../assets/images/topup/card-21.png";
import "./topup.css";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Money } from "../../assets/icons/topup-money.svg";
import { ReactComponent as Wallet } from "../../assets/icons/wallet-icon.svg";
import deposit_bonus_img from "../../assets/images/bonus-topup-img.png";
import deposit_bonus from "../../assets/images/deposit-bonus-banner.png";
import { formatNumber } from "../../helpers/price";
import Payments from "../../components/payments/payments";
import { useMemo } from "react";
import { mainApi } from "../../components/utils/main-api";
import { snackbarAction, snackbarClearAction } from "../../redux/snackbar-reducer";

const topup_filter = [
  {
    types: "Все",
  },
  {
    types: "Банковские карты",
  },
  {
    types: "Эл. кошельки",
  },
  {
    types: "Крипта",
  },
  {
    types: "Скины",
  },
  {
    types: "Платежи",
  },
];
  
  const topup_items = [
  /*{
    type: "Эл. кошельки",
    img: card1,
    name: "qiwi",
    type_payments: "freekassa",
    
  },*/
  {
    type: "Банковские карты",
    img: card2,
    name: "visa/mir",
    type_payments: "freekassa",
    include_service: "36"
  },
  {
    type: "Банковские карты",
    img: card16,
    name: "SBP (NSPC)",
    type_payments: "freekassa",
    include_service: "44"
  },
  /*{
    type: "Эл. кошельки",
    img: card3,
    name: "apple-pay",
    type_payments: "freekassa",
    
  },
  {
    type: "Банковские карты",
    img: card4,
    name: "visa",
    type_payments: "freekassa",
    
  },*/
  {
    type: "Эл. кошельки",
    img: card5,
    name: "u-money",
    type_payments: "freekassa",
    include_service: "6"
  },
  {
    type: "Банковские карты",
    img: card17,
    name: "visa RUB",
    type_payments: "freekassa",
    include_service: "4"
  },
  {
    type: "Банковские карты",
    img: card18,
    name: "VISA / MasterCard UAH",
    type_payments: "freekassa",
    include_service: "7"
  },
  {
    type: "Банковские карты",
    img: card19,
    name: "MasterCard RUB",
    type_payments: "freekassa",
    include_service: "8"
  },
  {
    type: "Банковские карты",
    img: card20,
    name: "mir",
    type_payments: "freekassa",
    include_service: "12"
    },
  {
    type: "Эл. кошельки",
    img: card21,
    name: "Online Bank",
    type_payments: "freekassa",
    include_service: "13"
  },
  {
    type: "Банковские карты",
    img: card16,
    name: "SBP",
    type_payments: "freekassa",
    include_service: "42"
  },/*
  {
    type: "Банковские карты",
    img: card6,
    name: "visa-turkiye",
    type_payments: "freekassa",
    
  },
  {
    type: "Эл. кошельки",
    img: card7,
    name: "online-bank",
    type_payments: "freekassa",
    
  },
  {
    type: "Эл. кошельки",
    img: card8,
    name: "pix",
    type_payments: "freekassa",
    
  },
  {
    type: "Банковские карты",
    img: card9,
    name: "union-pay",
    type_payments: "freekassa",
    
  },
  {
    type: "Крипта",
    img: card11,
    name: "tether",
    type_payments: "freekassa",
    
  },
  {
    type: "Крипта",
    img: card12,
    name: "litecoin",
    type_payments: "freekassa",
    
  },
  {
    type: "Крипта",
    img: card13,
    name: "btc",
    type_payments: "freekassa",
    
  },
  {
    type: "Крипта",
    img: card14,
    name: "eth",
    type_payments: "freekassa",
    
  },
  {
    type: "Крипта",
    img: card15,
    name: "btccash",
    type_payments: "freekassa",
    
  },*/
];

function Topup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const usersData = useSelector((state) => state.user.user);

  const [topupTypes, setTopupTypes] = useState(topup_items);
  const [isPaymentTypes, setIsPaymentTypes] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState("");
  const [activeFilter, setActiveFilter] = useState("");
  const [topupAmount, setTopupAmount] = useState(3000);
  const [email, setEmail] = useState('');

  const snackbarActions = (snackText, error) => {
    dispatch(snackbarAction({snackText, error}))
    setTimeout(() => {
      dispatch(snackbarClearAction())
    }, 2000);
  };

  const filteredTopupTypes = (type) => {
    setActiveFilter(type);
    const filtered = topup_items.filter((item) => item.type === type);
    setTopupTypes(filtered);
    if (type === "Все") {
      setTopupTypes(topup_items);
    }
    if (type === 'Платежи') {
      setIsPaymentTypes(true);
    } else {
      setIsPaymentTypes(false);
    }
  };

  const replenish = () => {
    if (!email) {
      snackbarActions("error", { error: 'Поле email не может быть пустым' })
    } else if (!selectedMethod) {
      snackbarActions("error", { error: 'Выберите тип платежа' })
    } else {
      mainApi
        .paymentsPOST({
          "email": email,
          "type_payments": selectedMethod.type_payments,
          "sum": parseFloat(topupAmount),
          "include_service": selectedMethod.include_service
        })
        .then((data) => {
          snackbarActions("Баланс пополнен")
        })
        .catch((error) => {
          console.log(error);
          snackbarActions("error", error)
        });
    }
    
  }

  React.useEffect(() => {
    document.title = `Пополнение баланса - Legadrop`;
  }, []);
  
  useEffect(() => {
    if (!usersData.is_logged) {
      navigate("/");
    }
  }, [navigate, usersData]);

  const content = useMemo(() => {
    if (isPaymentTypes) {
      return (
        <Payments />
      )
    } else {
      return (
        <>
          <div className="topup_cards_list">
            {topupTypes.map((item) => (
              <div
                key={item.name}
                onClick={() => setSelectedMethod(item)}
                className={`topup_cards_list_item ${
                  selectedMethod.name === item.name ? "selected_topup_type" : ""
                }`}
              >
                <img src={item.img} alt="" />
              </div>
            ))}
          </div>
          <div className="topup_alert">
            <p>
              Для пополнение баланса вы будете перемещены на сайт платежной
              системы
            </p>
          </div>
        </>
      )
    }
  }, [isPaymentTypes, selectedMethod, topupTypes])

  return (
    <div className="page_template topup_page">
      <div className="page_title">
        <h1>Пополнение баланса</h1>
      </div>
      <div className="topup_filters">
        {topup_filter.map((item) => (
          <div
            key={item.types}
            className={`topup_filter_item ${
              activeFilter === item.types ? "active_topup_filter" : ""
            }`}
            onClick={() => filteredTopupTypes(item.types)}
          >
            <p>{item.types}</p>
          </div>
        ))}
      </div>
      <div className="topup_content">
        <div className="topup_cards_list_wrapper">
          {content}
        </div>
        <div className="topuping_content">
          <div className="bonus_promocode_topup">
            <div className="topup_bonus_wrapper">
              <div className="topup_bonus">
                <img src={deposit_bonus} alt="" />
                <img src={deposit_bonus_img} className="topup_bonus_png" alt="" />
              </div>
              <input
                className="topup_bonus_input"
                type="text"
              />
              <input
                className="topup_bonus_input"
                type="text"
                placeholder="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
              }}
              />
            </div>
          </div>
          <div className="topup_block">
            <div className="topup_block_title">
              <p>ВВЕДИТЕ СУММУ ПОПОЛНЕНИЯ</p>
            </div>
            <div className="topup_amount">
              <Money />
              <input
                type="text"
                value={formatNumber(topupAmount)} 
                onChange={(e) => {
                  setTopupAmount(e.target.value.replace(/\s/g, ''))
                }}
              />
            </div>
            <div className="price_with_bonuses">
              <p>
                На баланс поступит: <span>3 200 ₽</span>
              </p>
            </div>

            <button className="topup_btn orange_btn" onClick={replenish}>
              ПОПОЛНИТЬ <Wallet />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Topup;
