import React, { useState } from "react";
import { ReactComponent as TitleIcon } from "../../assets/icons/cases-title-icon.svg";
import Card from "../card/card";
import { useEffect } from "react";
import { mainApi } from "../utils/main-api";

function CasesCategory({category_id, name}) {
  const [cases, setCases] = useState([]);

  useEffect(() => {
    mainApi
      .getAllCases(category_id)
      .then((userData) => {
        setCases(userData.results);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  }, []);

  return (
    <div>
      <div className="cases_block_title">
        <TitleIcon />
        <h1>{name}</h1>
      </div>
      <div className="case_block_content">
        {cases && cases?.map((item, i) => <Card data={item} key={i} />)}
      </div>
    </div>
  );
}

export default CasesCategory;
