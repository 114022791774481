import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { snackbarAction, snackbarClearAction } from "../../redux/snackbar-reducer";
import { loginUserAction } from "../../redux/user-reducer";

import { ReactComponent as ExitIcon } from "../../assets/icons/close-icon.svg";
import "./modal-shop.css";
import { mainApi } from "../utils/main-api";

function ModalShop({ setShopModal, getItems }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const snackbarActions = (snackText, error) => {
    dispatch(snackbarAction({snackText, error}))
    setTimeout(() => {
      dispatch(snackbarClearAction())
    }, 2000);
  };

  const buyItems = () => {
    mainApi
      .shopBuy(getItems.item_id)
      .then((res) => {
        snackbarActions(`Вы успешно купили ${res.name}`)
        mainApi
          .reEnter()
          .then((res) => {
            dispatch(loginUserAction(res));
          })
        setTimeout(() => {
          navigate(`/profile`);
          setShopModal(false)
        }, 1000);
      })
      .catch((error) => {
        console.log("error", error);
        snackbarActions("error", {error: "Недостаточно средств"});
      });
  };

  return (
    <div className="modal_wrapper_template">
      <div className="modal_template login_modal">
        <div className="modal_header_shop">
          <button
            className="modal_closer"
            onClick={() => setShopModal(false)}
          >
            <ExitIcon />
          </button>
        </div>
        <div
          className="card_wrapper_shop case_item_card"
        >
          <div className="card_image">
            <img
              src={getItems.image}
              alt=""
              title={getItems.name}
            />
          </div>
          <div className="card_description">
            <div className="card_name">
              <p>{getItems.name}</p>
            </div>
            <div className="card_prices">
              <p className="item_real_price">{getItems.price} ₽</p>
            </div>
          </div>
        </div>
        <div className="modal_btns_shop">
          <button className="green_btns" onClick={buyItems}>
            Подтвердить
          </button>
          <button className="orange_btns_shop" onClick={() => {setShopModal(false)}}>
           Отклонить
          </button>
        </div>
      </div>
    </div>
  );
}

export default ModalShop;
