import { useEffect, useState } from "react";
import { formatNumber } from "../../helpers/price";
import { mainApi } from "../utils/main-api";

import "./ref-output-item.css";

function RefOutputItem({ items }) {
  const [showMore, setShowMore] = useState(false);

  const [item, setItem] = useState();

  useEffect(() => {
    if (showMore) {
      mainApi
        .refOutputIdAction(items.ref_output_id)
        .then((data) => {
          setItem(data);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [showMore])
  
  return (
    <div className="ref_output_list_item_wrapper" key={items.ref_output_id}>
      <div className="ref_output_list_item">
        <div className="ref_output_left_block">
          <p className="ticket_number">#{items.ref_output_id}</p>
          <p className="history_item_descr">
            {items.type === "card" && 'Вывод на банковскую карту'}
            {items.type === "cryptocurrency" && 'Вывод на криптокошелек'}
            {items.type === "sbp" && 'Вывод по СБП'}
          </p>
          <p className={items.status === "created" ? 'history_status status2' : items.status === "completed" ?  'history_status status1' : items.status === "canceled" &&  'history_status status3'}>
            {items.status === "completed" && 'Завершенный'}
            {items.status === "created" && 'Созданный'}
            {items.status === "canceled" && 'Отмененный'}
          </p>
        </div>
        <div className="ref_output_right_block">
          <p className="history_price">{formatNumber(items.sum)} ₽</p>
          <div className="profile_item_action green_item" onClick={() => setShowMore(prev => !prev)}>
            <p>{showMore ? 'Скрыть' : 'Подробнее' }</p>
          </div>
        </div>
      </div>
      <div className={showMore ? "ref_output_list_item_show_more_wrapper show": "ref_output_list_item_show_more_wrapper"}>
        {showMore && (
          <div className="show_more_ref_output_block">
            <div className="show_more_ref_output_block_p">
              <p className="ticket_number">Номер телефона: {item?.phone || '-'}</p>
              <p className="ticket_number">Номер криптокошелька: {item?.crypto_number || '-'}</p>
              <p className="ticket_number">Номер карты: {item?.card_number || '-'}</p>
              <p className="ticket_number">Комментарий: {item?.comment || '-'}</p>
              {item && (
                <p className="ticket_number">{new Date(item.created_at).toISOString().replace(/T/, ' в ').replace(/\..+/, '').replace(/-/g, "-").replace(/:/g, ":")}</p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default RefOutputItem;
