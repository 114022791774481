import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { mainApi } from "../../../../components/utils/main-api";

import Pagination from "../../../../components/pagionation/pagination";
import PaginationLimit from "../../../../components/PaginationLimit/paginationLimit";
import { snackbarAction, snackbarClearAction } from "../../../../redux/snackbar-reducer";

import "./items.css";
import fav_case from "../../../../assets/images/fav-case.png";
import best_case from "../../../../assets/images/best-case.png";
import { ReactComponent as Loading } from "../../../../assets/icons/loading.svg";
import { loginUserAction } from "../../../../redux/user-reducer";
import { formatNumber } from "../../../../helpers/price";

function Items() {
  const dispatch = useDispatch();
  const usersData = useSelector((state) => state.user.user);

  const [items, setItems] = useState([]);
  const [dataLength, setDataLength] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState({ value: '10', label: '10' });

  const snackbarActions = (snackText, error) => {
    dispatch(snackbarAction({snackText, error}))
    setTimeout(() => {
      dispatch(snackbarClearAction())
    }, 2000);
  };

  const getUserItems = (limit, offset) => {
    mainApi
      .userItems(limit, offset)
      .then((data) => {
        setItems(data.results);
        setDataLength(data.count);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  const saleItems = (id, price) => {
    mainApi
      .deleteUserItemsAction(id)
      .then((data) => {
      })
      .catch((error) => {
      });
    setTimeout(() => {
      snackbarActions(`Предмет продан на сумму ${formatNumber(price)} р.`)
      getUserItems(limit.value, currentPage * limit.value);
      getUser();
    }, 500);
  }

  const outputs = (items) => {
    if (usersData.player_id === null) {
      snackbarActions("error", {error: 'Заполните поле UID'});
    } else {
      const body = {
        "player_id": usersData.player_id,
        "output_items": [
          {
            "item_id": `${items.id}`
          }
        ]
      }
      mainApi
        .outputsPostAction(body)
        .then((data) => {
          getUserItems(limit.value, currentPage * limit.value);
        })
        .catch((error) => {
          console.log("error", error);
          snackbarActions("error", error);
        });
    }
  }

  const getUser = () => {
    mainApi
      .reEnter()
      .then((res) => {
        dispatch(loginUserAction(res));
      })
      .catch((error) => {
        console.log("error", error);
      });  
  }

  const saleAll = () => {
    mainApi
      .deleteUserItemsAllAction()
      .then((data) => {
      })
      .catch((error) => {
      });
    setTimeout(() => {
      snackbarActions(`Все предметы проданы`)
      getUserItems(limit.value, currentPage * limit.value);
      getUser();
    }, 500);
  }

  useEffect(() => {
    getUserItems(limit.value, currentPage * limit.value);
  }, [currentPage, limit]);

  useEffect(() => {
    getUser();
  }, [])

  return (
    <div className="profile_content_wrapper items_content">
      <div className="profile_bests">
        <div className="profile_best_item">
          <div className="profile_best_item_content">
            <div className="profile_best_item_description">
              <h4>Любимый кейс</h4>
              <p>Кадзуха 100%</p>
            </div>

            <button className="open_case_profile">ОТКРЫТЬ</button>
          </div>
          <div className="profile_best_img">
            <img src={fav_case} alt="" />
          </div>
        </div>
        <div className="profile_best_item">
          <div className="profile_best_item_content">
            <div className="profile_best_item_description">
              <h4>ЛУЧШИЙ ДРОП</h4>
              <p>2 благославений полой луны</p>
            </div>

            <div className="case_price_profile">
              <p>899₽</p>
            </div>
          </div>
          <div className="profile_best_img">
            <img src={fav_case} alt="" />
          </div>
        </div>
      </div>
      <div className="btn_sale_all" onClick={saleAll}>
        <button>Продать всё</button>
      </div>
      <div className="profile_items_list">
        {items && items.map((items) => (
          <div className="profile_items_list_item" key={items.id}>
            <img src={items.image} alt="" />
            <div className="profile_items_list_item_desc">
              <p className="profile_items_name">{items.name}</p>
              <div className="profile_item_actions_list">
                
                  {items.withdrawal_process ? (
                  <div className="profile_item_action least_item">
                    <Loading />
                    <p>На выводе</p>
                  </div>
                ) : (
                    <>
                      <div className="profile_item_action green_item" onClick={() => outputs(items)}>
                        <p>Получить на аккаунт</p>
                      </div>
                      <div className="profile_item_action purple_item" onClick={() => saleItems(items.id, items.price)}>
                        <p>Продать за {formatNumber(items.price)} ₽</p>
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        ))}
        {items.length !==0 && (<div className="">
          <div className="cases_paginations">
            <Pagination
              pageCount={Math.ceil(dataLength / limit.value)}
              onPageChange={setCurrentPage}
              currentPage={currentPage}
            />
            </div>
            <div className="">
              <PaginationLimit limit={limit} setLimit={setLimit} onPageChange={setCurrentPage} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Items;
