import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loginUserAction } from "../../../redux/user-reducer";
import "../../login-auth/login-auth.css";
import { mainApi } from "../../utils/main-api";

function TGLogin({ setLoginModal, auth = false }) {
  const dispatch = useDispatch();

  useEffect(() => {
    const button = document.createElement('script')
    button.async = true
    button.src = 'https://telegram.org/js/telegram-widget.js?22'
    button.setAttribute('data-telegram-login', "legadrop_sign_up_bot")
    button.setAttribute('data-size', 'large')
    button.setAttribute('data-radius', '20')
    button.setAttribute('data-onauth', 'onTelegramAuth(user)')
    button.setAttribute('data-userpic', "false" )

    document.getElementById("telegram-widget-container").appendChild(button);

    window.onTelegramAuth = function (user) {
      mainApi
        .authTGAction(user)
        .then((userData) => {
          localStorage.setItem("token", userData.token);
          const user = {
            is_logged: true,
          };
          dispatch(loginUserAction(user));
          setLoginModal(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [])

  return (
    <div id="telegram-widget-container">
    </div>
  );
  
}

export default TGLogin;
