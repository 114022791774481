import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import Snacbar from "../../components/snackbar/snackbar";
import { mainApi } from "../../components/utils/main-api";

import "./shop-page.css";
import CardShop from "../../components/card-shop/card-shop";
import ModalShop from "../../components/modal-shop/modal-shop";

function ShopPage({ setShopModal, shopModal }) {
  const [items, setItems] = useState([]);
  const [isSnackbarVisible, setSnackbarVisible] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const [getItems, setGetItems] = useState({});

  const snackbarActions = (snackText) => {
    setSnackbarVisible(true);
    setSnackbarText(snackText);
    setTimeout(() => {
      setSnackbarVisible(false);
    }, 2000);
  };

  const getShopItems = () => {
    mainApi
      .shopItems()
      .then((data) => {
        setItems(data.results);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  useEffect(() => {
    getShopItems();
  }, []);

  return (
    <div className="page_termplate shop_page">
      {isSnackbarVisible ? (
        <Snacbar visible={isSnackbarVisible} text={snackbarText} />
      ) : (
        ""
      )}
      <div className="shop_page_title">
        <h1>Магазин примогемов Геншин Импакт</h1>
      </div>
      <div className="shop_page_wrapper">
        <div className="cases_block cases_characters">
          <div className="case_block_content">
            {items && items.map((item) => (
              <CardShop data={item} setShopModal={setShopModal} key={item.item_id} setGetItems={setGetItems} />
            ))}
          </div>
        </div>
      </div>
      <div className="about_shop_page">
        <p>
          На нашем проекте вы можете приобрести кристаллы, благословения луны и
          прочий игровой донат-ассортимент для Genshin Impact. Количество
          кристаллов, которое вы захотите вывести, отправляется прямиком в игру
          моментально, остаётся только указать свой UID, других данных не
          требуется Цены на сайте существенно ниже, чем в самой игре, у каждого
          есть возможность сэкономить большой процент на покупке кристаллов
          сотворения
        </p>
      </div>
      {shopModal && (
        <ModalShop setShopModal={setShopModal} getItems={getItems} />
      )}
    </div>
  );
}

export default ShopPage;
