import { useEffect } from "react";
import { useState } from "react";
import { formatNumber } from "../../helpers/price";

function LastWinners({ items }) {
  const [newItems, setNewItems] = useState(items.slice(0, 3));
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (showMore) {
      setNewItems(items)
    }
  }, [items, showMore]);

  return (
    <>
      {newItems.length !== 0 && (
        <button className="giveaway_btn last_winners_btn">
          Последние победители
        </button>
      )}
      <div className="giveaway_items_list">
        {newItems.map((items, i) => (
          <div className="giveaway_list_item" key={i}>
            <div className="giveaway_list_item_descr">
              <img src={`${'https://server.primoloot.com'}${items.photo}`} alt="" />
              <div>
                <p className="giveaway_text_temp">{items.item.name}</p>
                <h4>{formatNumber(items.item.price)} ₽</h4>
                <p className="giveaway_text_temp">{items.date}</p>
              </div>
            </div>
            <div className="giveaway_list_item_img">
              <img src={`${'https://server.primoloot.com'}${items.item.image}`} alt="" />
            </div>
          </div>
        ))}
      </div>
      {(items.length > 3 && !showMore) && (
        <button className="giveaway_btn show_more_giveaway grey_icon" onClick={() => setShowMore(true)}>
          Показать еще
        </button>
      )}
    </>        
  );
}

export default LastWinners;
