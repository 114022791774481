import { useEffect, useState } from "react";
import { formatNumber } from "../../helpers/price";
import PaginationLimit from "../PaginationLimit/paginationLimit";
import Pagination from "../pagionation/pagination";
import { mainApi } from "../utils/main-api";
import "./payments.css";

function Payments() {
  const [items, setItems] = useState([]);
  const [dataLength, setDataLength] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState({ value: '10', label: '10' });

  const getPaymentsItems = (limit, offset) => {
    mainApi
      .payments(limit, offset)
      .then((data) => {
        setItems(data.results);
        setDataLength(data.count);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  useEffect(() => {
    getPaymentsItems(limit.value, currentPage * limit.value);
  }, [currentPage, limit]);

  return (
    <div className="payments-wrapper">
      {items && items.map((items) => (
        <div className="ref_output_list_item_wrapper" key={items.order_id}>
          <div className="ref_output_list_item">
            <div className="ref_output_left_block">
              <p className="ticket_number">#{items.order_id}</p>
              <p className="history_item_descr">
                {items.type_payments === "lava" && 'Платежная система ЛАВА(LAVA)'}
                {items.type_payments === "yookassa" && 'Платежная система ЮКасса(Yookassa)'}
                {items.type_payments === "freekassa" && 'Платежная система ФРИКАССА(Freekassa)'}
              </p>
              <p className="ticket_number">Почта: {items?.email || '-'}</p>
            </div>
            <div className="ref_output_right_block">
              <p className="history_price">{formatNumber(items.sum)} ₽</p>
              <p className={items.status === "create" ? 'history_status_payments status2' : items.status === "success" ?  'history_status_payments status1' : items.status === "expired" ?  'history_status_payments status3' : 'history_status_payments status4'}>
                {items.status === "success" && 'Оплачен'}
                {items.status === "create" && 'Создан'}
                {items.status === "manually approved" && 'Одобрен вручную'}
                {items.status === "expired" && 'Отменен'}
              </p>
              <p className={`history_status_payments ${items.active ? 'status1' : 'status3'}`}>
                {items.active === false && 'Не активный'}
                {items.active === true && 'Активный'}
              </p>
              {items && (
                <p className="ticket_number">{new Date(items.created_at).toISOString().replace(/T/, ' в ').replace(/\..+/, '').replace(/-/g, "-").replace(/:/g, ":")}</p>
              )}
            </div>
          </div>
        </div>
      ))}
      {items.length !==0 && (<div className="">
          <div className="cases_paginations">
            <Pagination
              pageCount={Math.ceil(dataLength / limit.value)}
              onPageChange={setCurrentPage}
              currentPage={currentPage}
            />
            </div>
            <div className="">
              <PaginationLimit limit={limit} setLimit={setLimit} onPageChange={setCurrentPage} />
            </div>
          </div>
        )}
    </div>
  );
}

export default Payments;
