import React from "react";
import "./card-shop.css";

function CardShop({ data, setShopModal, setGetItems }) {
  const buyItems = () => {
    setGetItems(data);
    setShopModal(true);
  };

  return (
    <div
      className="card_wrapper case_item_card"
      //onClick={() => aboutItem(data)}
    >
      <div className="card_image">
        <img
          src={data.image}
          alt=""
          title={data.name}
        />
      </div>
      <div className="card_description">
        <div className="card_name">
          <p>{data.name}</p>
        </div>
        <div className="card_prices">
          {/*<p className="item_real_price">{data.real_price}₽</p>
          {data.discount_price ? (
            <p className="item_discount_price">{data.discount_price} ₽</p>
          ) : (
            ""
          )}*/}
          <p className="item_real_price">{data.price} ₽</p>
        </div>
        <div className="shop_btns_wrapper">
          <button className="green_btns" onClick={buyItems}>
            КУПИТЬ
          </button>
        </div>
      </div>
    </div>
  );
}

export default CardShop;
