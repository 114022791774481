import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { loginUserAction } from "../../redux/user-reducer";
import { mainApi } from "../utils/main-api";
import GoogleLoginModal from "../auth-socials/google-login/google-login";
import MailRuOAuth from "../auth-socials/mail.ru-login/mail.ru-login";
import TGLogin from "../auth-socials/tg-login/tg-login";
import VKFloatingLoginComponent from "../auth-socials/vk-login/vk-login";
import YandexAuthButton from "../auth-socials/yandex-login/yandex-login";
import { snackbarAction, snackbarClearAction } from "../../redux/snackbar-reducer";

import { ReactComponent as ExitIcon } from "../../assets/icons/close-icon.svg";
import { ReactComponent as GoogleIcon } from "../../assets/icons/auth-icons/google-icon.svg";
import { ReactComponent as PasswordIcon } from "../../assets/icons/auth-icons/password-eye.svg";
import "./login-auth.css";

function LoginModal({ setLoginModal, setAuthModalType }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");
  const [userPassword, setPassword] = useState("");

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [vkData, setVkData] = useState({});
  const [yandexData, setYandexData] = useState({});

  const snackbarActions = (snackText, error) => {
    dispatch(snackbarAction({snackText, error}))
    setTimeout(() => {
      dispatch(snackbarClearAction())
    }, 2000);
  };

  const loginUser = async () => {
    if (userName && userPassword) {
      const formData = {
        username: userName,
        password: userPassword,
      };
       mainApi
        .signInAction(formData)
        .then((data) => {
          localStorage.setItem("token", data);
          const user = {
            is_logged: true,
          };
          dispatch(loginUserAction(user));
          setLoginModal(false);
        })
        .catch((error) => {
          snackbarActions("error", {
            error: error
          });
        });
    }
  };

  const authGoogle = (data) => {
    console.log(data)
    mainApi
      .authGoogleAction(data)
      .then((res) => {
        localStorage.setItem("token", res.access_token);
        const is_logged = {
          is_logged: true,
        };

        dispatch(loginUserAction(is_logged));
        dispatch(loginUserAction(res.user));
        setLoginModal(false);
      })
      .catch((error) => {
        console.log("google error");
      });
  };

  useEffect(() => {
    if (vkData && vkData.id) {
      const user = {
        username: `${vkData.first_name}_${vkData.last_name}`,
        vkontakte_id: vkData.id,
        picture: vkData.avatar,
      };

      mainApi
        .authVKAction()
        .then((userData) => {
          localStorage.setItem("token", userData.access_token);
          const is_logged = {
            is_logged: true,
          };
          dispatch(loginUserAction(is_logged));
          dispatch(loginUserAction(userData.user));
          setLoginModal(false);
        })
        .catch(() => {
          console.log("error");
        });
    }
  }, [dispatch, navigate, setLoginModal, vkData]);

  useEffect(() => {
    if (yandexData && yandexData.id) {
      const user = {
        yandex_id: yandexData.id,
        email: yandexData.default_email,
        picture: `https://avatars.yandex.net/get-yapic/${yandexData.default_avatar_id}/islands-200`,
        username: yandexData.login,
      };
      mainApi
        .authYandexAction(user)
        .then((userData) => {
          localStorage.setItem("token", userData.access_token);
          const user = {
            is_logged: true,
          };
          dispatch(loginUserAction(user));
          dispatch(loginUserAction(userData.user));
          setLoginModal(false);
        })
        .catch((error) => {
          console.log("error");
        });
    }
  }, [dispatch, navigate, setLoginModal, yandexData]);

  return (
    <div className="modal_wrapper_template">
      <div className="modal_template login_modal">
        <div className="modal_header">
          <p className="modal_title">Вход</p>
          <button
            className="modal_closer"
            onClick={() => setLoginModal(false)}
          >
            <ExitIcon />
          </button>
        </div>
        <p className="login_greeting">Добро пожаловать в LegaDrop</p>
        <div className="auth_wrapper">
          <div className="auth_content">
            <div className="auth_socials">
              <div className="google_auth_btn_wrapper">
                <div className="google_auth_btn">
                  <GoogleIcon
                    className="google_fake"
                  />
                  <GoogleLoginModal authGoogle={authGoogle} />
                </div>
              </div>
              <VKFloatingLoginComponent
                setVkData={setVkData}
                setLoginModal={setLoginModal}
              />
              <div className="tg_login_btn">
                <TGLogin setLoginModal={setLoginModal} />
              </div>
              <MailRuOAuth setLoginModal={setLoginModal} />
              <YandexAuthButton setYandexData={setYandexData} />
            </div>
            <div className="login_selection">
              <div className="or_line"></div>
              <p>ИЛИ</p>
              <div className="or_line"></div>
            </div>
            <div className="aouth_login_inputs">
              <input
                type="email"
                name="username"
                className="aouth_login_input"
                placeholder="Email / Логин"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
              <div className="auth_password_input">
                <input
                  type={passwordVisible ? "text" : "password"}
                  className="aouth_login_input"
                  placeholder="Пароль"
                  name="password"
                  value={userPassword}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <PasswordIcon
                  onClick={() => setPasswordVisible(!passwordVisible)}
                  className={
                    passwordVisible
                      ? "hide_password active_auth_password"
                      : "hide_password"
                  }
                />
              </div>
            </div>
          </div>
          <div className="password_recovery_btn">
            <p>Забыли пароль?</p>
          </div>
          <button className="submit_btn" onClick={loginUser}>
            ВОЙТИ
          </button>
          <div className="auth_types">
            <p>
              Еще нет аккаунта?{" "}
              <span onClick={() => setAuthModalType(false)}>
                Зарегистрируйтесь
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginModal;
