import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./upgrade.css";
import updating_bottom from "../../assets/images/update-bottom.png";
import updating_chance from "../../assets/images/upgrade.png";
import CaseItem from "../../components/case-item/case-item";
import { mainApi } from "../../components/utils/main-api";
import Pagination from "../../components/pagionation/pagination";
import PaginationLimit from "../../components/PaginationLimit/paginationLimit";
import { snackbarAction, snackbarClearAction } from "../../redux/snackbar-reducer";
import { formatNumber } from "../../helpers/price";
import {
  CircularProgressbar,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ArrowUpgrade from "./arrow-upgrade";
import { loginUserAction } from "../../redux/user-reducer";
import check from "../../assets/images/check-mark.png";
import cross from "../../assets/images/cross-mark.png";
import money from "../../assets/images/balance.png";
import useDebounce from "../../hooks/useDebounce";

function Upgrade() {
  const dispatch = useDispatch();
  const usersData = useSelector((state) => state.user.user);

  const [inventoryItems, setInventoryItems] = useState([]);
  const [dataLengthInventoryItems, setDataLengthInventoryItems] = useState();
  const [currentPageInventoryItems, setCurrentPageInventoryItems] = useState(0);
  const [limitInventoryItems, setLimitInventoryItems] = useState({ value: '10', label: '10' });
  const [inventoryItemSelected, setInventoryItemSelected] = useState();

  const [getItems, setGetItems] = useState([]);
  const [dataLengthGetItems, setDataLengthGetItems] = useState();
  const [currentPageGetItems, setCurrentPageGetItems] = useState(0);
  const [limitGetItems, setLimitGetItems] = useState({ value: '10', label: '10' });
  const [getItemSelected, setGetItemSelected] = useState();

  const [upgradePercent, setUpgradePercent] = useState('00.00');
  const [upgradeRatio, setUpgradeRatio] = useState('00.00');

  const [valueBalance, setValueBalance] = useState('');
  const [isBalance, setIsBalance] = useState(false);
  const [balance, setBalance] = useState();

  const [isUpgrade, setIsUpgrade] = useState(false);
  const [upgrade, setUpgrade] = useState('');
  const [minStopAngle, setMinStopAngle] = useState(0);
  const [maxStopAngle, setMaxStopAngle] = useState(0);
  const [activeProgressbar, setActiveProgressbar] = useState(false);
  const [activeArrow, setActiveArrow] = useState(false);

  const [sort, setSort] = useState('price');
  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce(search, 500);

  const snackbarActions = (snackText, error) => {
    dispatch(snackbarAction({snackText, error}))
    setTimeout(() => {
      dispatch(snackbarClearAction())
    }, 2000);
  };

  const getInventoryItems = (limit, offset) => {
    mainApi
      .userItems(limit, offset)
      .then((data) => {
        setInventoryItems(data.results);
        setDataLengthInventoryItems(data.count);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  const getUpgradeItems = (limit, offset, sort, search, price, item) => {
    mainApi
      .upgradeItems(limit, offset, sort, search, price, item)
      .then((data) => {
        setGetItems(data.results);
        setDataLengthGetItems(data.count);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  const catchUpgradeData = (error) => {
    snackbarActions("error", error);
    console.log("error", error);
    setUpgradePercent('00.00');
    setUpgradeRatio('00.00');
    setActiveProgressbar(false);
  }

  const thenUpgradeData = (data) => {
    setUpgradePercent(Math.round(data.upgrade_percent * 100) / 100);
    setUpgradeRatio(Math.round(data.upgrade_ratio * 100) / 100);
    setActiveProgressbar(true);
  } 

  const getUpgradeData = () => {
    if (inventoryItemSelected && getItemSelected) {
      mainApi
      .upgradeUpgradeData({
        "upgrade_items": [
          inventoryItemSelected.id
        ],
        "upgraded_item": getItemSelected.item_id
      })
      .then((data) => {
        thenUpgradeData(data);
      })
      .catch((error) => {
        catchUpgradeData(error)
      });
    }
    if (isBalance && balance && getItemSelected) {
      mainApi
      .upgradeUpgradeData({
        "upgraded_item": getItemSelected.item_id,
        "balance": balance
      })
      .then((data) => {
        thenUpgradeData(data);
      })
      .catch((error) => {
        catchUpgradeData(error)
      });
    }
  }

  const getItemUpgradeUser = (item, action) => {
    if (isUpgrade) {
      setIsUpgrade(false);
    }
    mainApi
      .upgradeGetMinimalValues({
        "upgraded_item": item.item_id
      })
      .then((data) => {
        action(item);
      })
      .catch((error) => {
        snackbarActions("error", { error: "Предмет не доступен для апгрейда" });
        console.log("error: ", error);
      });
  }

  const changeUpgrade = (status) => {
    setIsUpgrade(true);
    setActiveArrow(true);
    getUser();
    if (status) {
      setMinStopAngle(0)
      setMaxStopAngle(upgradePercent * 360 / 100)
    } else {
      setMinStopAngle(upgradePercent * 360 / 100)
      setMaxStopAngle(360)
    }

    setTimeout(() => {
      setActiveProgressbar(false);
      setUpgradePercent('00.00');
      setUpgradeRatio('00.00');
      setActiveArrow(false);
      setUpgrade(`${status}`)
      getInventoryItems(limitInventoryItems.value, currentPageInventoryItems * limitInventoryItems.value)
    }, [8000])
  }

  const getUpgradeUpgradeItems = () => {
    if (inventoryItemSelected && getItemSelected) {
      mainApi
      .upgradeUpgradeItems({
        "upgrade_items": [
          inventoryItemSelected.id
        ],
        "upgraded_item": getItemSelected.item_id
      })
        .then((data) => {
          changeUpgrade(true);
      })
        .catch((error) => {
          console.log("error", error);
          changeUpgrade(false);
      });
    }
    if (isBalance && balance && getItemSelected) {
      mainApi
      .upgradeUpgradeItems({
        "upgraded_item": getItemSelected.item_id,
        "balance": balance
      })
        .then((data) => {
          changeUpgrade(true);
      })
        .catch((error) => {
          console.log("error", error);
          changeUpgrade(false);
      });
    }
  }

  const getUser = () => {
    mainApi
      .reEnter()
      .then((res) => {
        dispatch(loginUserAction(res));
      })
      .catch((error) => {
        console.log("error", error);
      });  
  }

  const changeChoice = (boolean) => {
    setIsBalance(boolean);
    setGetItems([]);
    setDataLengthGetItems(0);
    if (isBalance && !isUpgrade) {
      setBalance();
      setValueBalance('');
    }
    if(!isBalance && !isUpgrade){
      setInventoryItemSelected();
    }
    if (!isUpgrade) {
      setActiveProgressbar(false);
      setUpgradePercent('00.00');
      setUpgradeRatio('00.00');
    }
  }

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    getUser();
  }, [])


  useEffect(() => {
    getUpgradeData();
  }, [inventoryItemSelected, getItemSelected, balance])

  useEffect(() => {
    if (!isUpgrade) {
      setInventoryItemSelected();
      setValueBalance('');
      setBalance();
      setGetItemSelected();
      setUpgrade();
      setUpgradePercent('00.00');
      setUpgradeRatio('00.00');
      setActiveProgressbar(false);
      setGetItems([]);
      setDataLengthGetItems(0);
    }
  }, [isUpgrade])

  useEffect(() => {
    getInventoryItems(limitInventoryItems.value, currentPageInventoryItems * limitInventoryItems.value);
  }, [currentPageInventoryItems, limitInventoryItems]);

  useEffect(() => {
    console.log(balance, inventoryItemSelected)
    if (balance || inventoryItemSelected) {
      getUpgradeItems(limitGetItems.value, currentPageGetItems * limitGetItems.value, sort, search, balance, inventoryItemSelected)
    }
  }, [currentPageGetItems, limitGetItems, sort, debouncedSearch, balance, inventoryItemSelected]);

  return (
    <div className="page_template upgrade_page">
      <div className="upgrade_block">
        <div className="upgrade_wrapper">
          <div className="updating_item upgrade_item_left">
            {inventoryItemSelected ? (
              <img className="updating_item_img" src={inventoryItemSelected.image} alt="" />
            ) : (
              (isBalance && balance) || (balance && isUpgrade)) ? (
                <img className="updating_item_img" src={money} alt="" />
              ) : (
                <div className="updating_item_img"/>
              )}
            <img className="updating_bottom_img" src={updating_bottom} alt="" />
            <p>{isBalance ? `Ваш баланс: ${formatNumber(usersData.balance)} ₽`: 'Выбери предмет, который мы будем апгрейдить' }</p>
          </div>
          <div className="updating_chance">
            <img src={updating_chance} alt="" />
            {activeArrow && (
              <ArrowUpgrade minStopAngle={minStopAngle} maxStopAngle={maxStopAngle} />
            )}
            {activeProgressbar && (
              <CircularProgressbar
                className="circular-progressbar"
                value={upgradePercent}
                styles={buildStyles({
                  pathColor: "#69dd89",
                  trailColor: "#10162C",
                  strokeLinecap: "butt",
                })}
                strokeWidth={2}
              />
            )}
            <div className={upgrade ? "updating_percent_img": "updating_percent"}>
              {upgrade && (
                <>
                  {upgrade === 'true' ? <img className="upgrade_svg" src={check} alt="" /> : upgrade === 'false' ? <img className="upgrade_svg" src={cross} alt="" /> : '' }
                </>
              )}
              {!isUpgrade && (
                <>
                  <p className={(upgradePercent >= 50 && activeProgressbar) ? 'upgrade_percent_green' : (upgradePercent < 50 && activeProgressbar) ? 'upgrade_percent_red' : ''}>{upgradePercent}%</p>
                  <span>шанс возвышения</span>
                  <p>{upgradeRatio}</p>
                  <span>коэффициент апгрейда</span>
                </>
              )}
            </div>
          </div>
          <div className="updating_item updating_item_to upgrade_item_right">
            {getItemSelected ? (
              <img className="updating_item_img" src={getItemSelected.image} alt="" />) : (
              <div className="updating_item_img"/>
            )}
            <img className="updating_bottom_img" src={updating_bottom} alt="" />
            <p>
              Выбери предмет, который будем <br /> пытаться получить
            </p>
          </div>
        </div>
        <div className="update_btn">
          <div className="wrapper_update_btn_block">
            <div className="wrapper_upgrade_price">
              {inventoryItemSelected && (
                <p>{`${formatNumber(inventoryItemSelected.price)} ₽`}</p>
              )}
              {isBalance && (
                <>
                  {valueBalance ? (<p>{`${formatNumber(valueBalance)} ₽`}</p>): (<p className="h-20"></p>)}
                  <input
                    type="range"
                    className="upgrade_balance_input"
                    min="20"
                    max={usersData.balance}
                    step='10'
                    value={valueBalance || 20}
                    onChange={(e) => {
                      setValueBalance(e.target.value)
                      if (isUpgrade) {
                          setIsUpgrade(false);
                        }
                    }}
                  />
                  <input
                    type="text"
                    className="upgrade_balance_input_text"
                    value={formatNumber(valueBalance)}
                    onChange={(e) => {
                        setValueBalance(e.target.value.replace(/\s/g, ''))
                      
                      if (isUpgrade) {
                          setIsUpgrade(false);
                        }
                    }}
                  />
                  <button onClick={() => setBalance(valueBalance)} className="btn_upgrade_balance">Внести сумму в апгрейд</button>
                </>
              )}
            </div>
            <div className="update_btn_wrapper">
              {!isUpgrade && (<button className="main_btn_temp main_btn_green" onClick={getUpgradeUpgradeItems}>ВОЗВЫСИТЬ</button>)}
              {upgrade && (
                <>
                  {upgrade === 'true' ? (
                    <>
                      <div className="update_success_wrapper">
                        <h3 className="update_h3_success">ПОБЕДА</h3>
                        <p className="update_p_success">Большая удача</p>
                      </div>
                      <button className="main_btn_temp main_btn_green" onClick={() => setIsUpgrade(false)}>Новый апгрейд</button>
                    </>
                  ) : upgrade === 'false' ? (
                      <>
                        <div className="update_success_wrapper">
                          <p className="update_p_success">ЭТО БЫЛО СЛИШКОМ РИСКОВАНО</p>
                        </div>
                        <button className="main_btn_temp main_btn_green" onClick={() => setIsUpgrade(false)}>Попробовать еще раз</button>
                      </>
                  ) : ''}
                  
                </>
              )}
            </div>
            <div className="wrapper_upgrade_price">
              {getItemSelected && (
                <p>{`${formatNumber(getItemSelected.price)} ₽`}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="update_middle_block">
        <div className="update_change_block">
          <button className={isBalance ? 'default_change_btn' : 'active_change_btn'} onClick={() => changeChoice(false)}>
            <p>Инвентарь</p>
          </button>
          <button className={isBalance ? 'active_change_btn' : 'default_change_btn'} onClick={() => changeChoice(true)}>
            <p>Баланс</p>
          </button>
        </div>
        <div className="">
          <input
            type="text"
            className="aouth_login_input"
            placeholder="Поиск по имени"
            value={search}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="upgrade_secondary_block">
        <div className="upgrade_secondary_block_item">
          {isBalance ? (
            <div className="upgrade_secondary_block_item_title">
              <p>Выберите сумму с вашего баланса</p>
            </div>
          ): (
            <>
              <div className="upgrade_secondary_block_item_title">
                <p>Ваш инвентарь</p>
              </div>
              <div className="upgrade_secondary_items_wrapper">
                {inventoryItems && inventoryItems.map((item) => (
                  <div className="upgrade_case-item-wrapper" key={item.id} onClick={() => getItemUpgradeUser(item, setInventoryItemSelected)}>
                    <CaseItem item={item} isActive={(item===inventoryItemSelected && !isUpgrade)} />
                  </div>
                ))}
              </div>
              {inventoryItems.length !== 0 && (<div className="">
                <div className="cases_paginations">
                  <Pagination
                    pageCount={Math.ceil(dataLengthInventoryItems / limitInventoryItems.value)}
                    onPageChange={setCurrentPageInventoryItems}
                    currentPage={currentPageInventoryItems}
                  />
                  </div>
                  <div className="">
                    <PaginationLimit limit={limitInventoryItems} setLimit={setLimitInventoryItems} onPageChange={setCurrentPageInventoryItems} />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="upgrade_secondary_block_item">
          <div className="upgrade_secondary_block_item_title">
            <p>Получить</p>
            <button
              className="upgrade_block_sort"
             onClick={() => {
                if (sort !== 'price') {
                  setSort('price')
                } 
                if (sort === 'price') {
                  setSort('-price')
                }
              }}
            >
              <p>Цена</p>
              <svg
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                transform={sort === '-price' ? "rotate(180)" : "rotate(0)"}
              >
                <path d="M6 0L0 8H12L6 0Z" fill="#E0E6FF"/>
              </svg>
            </button>
          </div>
          <div className="upgrade_secondary_items_wrapper">
            {getItems && getItems.map((item) => (
              <div className="upgrade_case-item-wrapper" key={item.item_id} onClick={() => getItemUpgradeUser(item, setGetItemSelected)}>
                <CaseItem item={item} isActive={item===getItemSelected && !isUpgrade} />
              </div>
            ))}
          </div>
          {getItems.length !== 0 && (<div className="">
            <div className="cases_paginations">
              <Pagination
                pageCount={Math.ceil(dataLengthGetItems / limitGetItems.value)}
                onPageChange={setCurrentPageGetItems}
                currentPage={currentPageGetItems}
              />
              </div>
              <div className="">
                <PaginationLimit limit={limitGetItems} setLimit={setLimitGetItems} onPageChange={setCurrentPageGetItems} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Upgrade;
